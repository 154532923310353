
.container-game {
    position: relative;
    width: 100%;
    min-height: 450px;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    /* background-color: #ADD8E6; */
    overflow: hidden; /* Para asegurarte de que el pseudo-elemento no se salga del contenedor */
}

.container-game::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/fondo_1.webp");
    background-size: cover;
    background-position: center;
    opacity: 0.65;
    z-index: -1; 
}

.container-tiempo-game {
    display: flex;
    justify-content: end;
    padding: 20px 20px 0px 0px;
}

.container-tiempo-game .tiempo-game {
    font-size: 30px;
    color: white;
}

.maze {
    /*viejo*/
    /*
    display: grid;
    grid-template-rows: repeat(11, 18px);
    grid-template-columns: repeat(21, 18px);
    */
    /* gap: 2px; */
    background-color: transparent;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 600px; /* Ajusta el ancho máximo según sea necesario */
}



.maze-row {
    display: flex;
}

.maze-cell {
    width: 1vw;
    height: 1vw;
    max-width: 28px;
    max-height: 28px;
    background-color: lightgray;
    /* border: 1px solid #000; */
}

.maze-cell-1 {
    width: 1vw;
    height: 1vw;
    max-width: 28px;
    max-height: 28px;
    background-color: lightskyblue;
    /* border: 1px solid #000; */
}

.maze-cell-2 {
    width: 1vw;
    height: 1vw;
    max-width: 28px;
    max-height: 28px;
    background-color: lightgray;
    /* border: 1px solid #000; */
}

.maze-cell-3 {
    width: 1vw;
    height: 1vw;
    max-width: 28px;
    max-height: 28px;
    background-color: lightgray;
    /* border: 1px solid #000; */
}

.wall {
    background-color: rgb(14, 102, 11);
    /*border: 1px solid green;*/
    background-image: url("../img/wall-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.wall-1 {
    background-color: rgb(14, 102, 11);
    /*border: 1px solid green;*/
    background-image: url("../img/wall-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.wall-2 {
    background-color: rgb(14, 102, 11);
    /*border: 1px solid green;*/
    background-image: url("../img/wall-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.wall-3 {
    background-color: rgb(14, 102, 11);
    /*border: 1px solid green;*/
    background-image: url("../img/wall-3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.player {
    background-color: lightgray;
    background-image: url("../img/runer-2.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.player-1 {
    background-color: lightskyblue;
    background-image: url("../img/runer-2.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.player-2 {
    background-color: lightgray;
    background-image: url("../img/runer-2.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.player-3 {
    background-color: lightgray;
    background-image: url("../img/runer-2.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.exit {
    /*background-color: rgb(255, 179, 1);*/
    background-color: yellowgreen;
    background-image: url("../img/door.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.exit-1 {
    /*background-color: rgb(255, 179, 1);*/
    background-color: yellowgreen;
    background-image: url("../img/door.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.exit-2 {
    /*background-color: rgb(255, 179, 1);*/
    background-color: yellowgreen;
    background-image: url("../img/door.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.exit-3 {
    /*background-color: rgb(255, 179, 1);*/
    background-color: yellowgreen;
    background-image: url("../img/door.png");
    background-repeat: no-repeat;
    background-size: cover;
}

@media (min-aspect-ratio: 1/1) {
    
    .maze-cell{
        width: 2.8vh;
        height: 2.8vh;
    }

    .maze-cell-1{
        width: 2.8vh;
        height: 2.8vh;
    }

    .maze-cell-2{
        width: 2.8vh;
        height: 2.8vh;
    }

    .maze-cell-3{
        width: 2.8vh;
        height: 2.8vh;
    }
  }
  
  /* Estilo cuando la altura es mayor que el ancho */
  @media (max-aspect-ratio: 1/1) {
    .maze-cell{
        width: 5vw;
        height: 5vw;
    }

    .maze-cell-1{
        width: .8rem;
        height: .8rem;
        background-color: lightskyblue;
    }

    .maze-cell-2{
        width: .6rem;
        height: .6rem;
        background-color: lightskyblue;
    }

    .maze-cell-3{
        width: .5rem;
        height: .5rem;
        background-color: lightskyblue;
    }
  }