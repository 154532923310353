.busca-la-diferencia {
    text-align: center;
}

.imagenes {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    position: relative;
    margin-bottom: 3rem;
    overflow: hidden;
}

.imagen {
    position: relative;
    width: 100%;
    height: auto;
}


.imagen img {
    width: 90%;
    height: auto;
}

.imagen-completa {
    position: relative;
}

.imagen-con-diferencias {
    position: relative;
}

.diferencia {
    opacity: 0;
    position: absolute;
    border: 3px solid rgb(250, 83, 83);
    border-radius: 100%;
    border-top-color: rgb(255, 0, 0);
    border-bottom-color: rgb(255, 0, 0);
    width: 30px;
    height: 30px;
    animation:  pulse 1.5s ease-in-out infinite, loading 1.5s infinite linear;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 rgba(255, 0, 0, 0.5);
    }
    50% {
        box-shadow: 0 0 10px rgb(255, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 rgba(255, 0, 0, 0.5);
    }
}


.diferencia.encontrada {
    opacity: 1;
}

.error {
    position: absolute;
    width: 30px !important;
    height: 30px;
}

@media screen and (max-width: 950px) {
    .imagenes {
        width: 100%;
        display: block;
        position: relative;
        margin-bottom: 3rem;
    }

    .imagen {
        width: 100%;
        position: relative;
    }

    .imagen img {
        position: relative;
        width: 90%;
        height: auto;
        object-fit: contain;
        margin-bottom: 1rem;

    }

    .error {
        position: absolute;
        width: 30px !important;
        height: 30px;
    }

}