.matching-game {
  text-align: center;
  margin: 20px;
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(4, 70px); /* 4 columnas fijas */
  grid-auto-rows: 80px; /* Filas automáticas */
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.cards-grid-3 {
  display: grid;
  grid-template-columns: repeat(6, 70px); /* 4 columnas fijas */
  grid-auto-rows: 80px; /* Filas automáticas */
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

/* 
.cardGame {
  width: 60px;
  height: 80px;
  position: relative;
  perspective: 1000px;
  cursor: pointer;
} */

.cardGame {
  width: 60px;
  height: 80px;
  perspective: 1000px;
  background-color: white;
  border-radius: 10px;
}

.cardGame img {
  width: 100%;
  height: 80px;
  object-fit: contain;
  border-radius: 8px;
}


.cardGame div {
  width: 60px;  
  height: 80px;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  transition: transform 0.6s;
}

.cardGame-front{
  width: 100%;
  height: 80px;
}

.cardGame-back {
  width: 100%;
  height: 80px;
  background-image: url('../img/carta_back.jpg'); 
  background-size: cover;
  background-position: center;
  transform: rotateY(180deg);
}

.cardGame.flipped .cardGame-front {
  transform: rotateY(180deg);
}

.cardGame.flipped .cardGame-back {
  transform: rotateY(0deg);
}

h2 {
  font-size: 28px;
  color: #666;
}

p {
  font-size: 18px;
  color: #666;
}


@media screen and (max-width: 600px) {

  .cards-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 70px); /* 4 columnas fijas */
    grid-auto-rows: 80px; /* Filas automáticas */
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }
  
}