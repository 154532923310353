.number-sequencing-game {
    text-align: center;
    margin: 20px;
  }
  
  .numbers-grid {
    display: grid;
    grid-template-columns: repeat(5, 60px);
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .number-item {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #333;
    border-radius: 8px;
    background-color: #f0f0f0;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .number-item:hover {
    background-color: #d0d0d0;
  }
  