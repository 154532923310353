* {
    box-sizing: border-box;
}

.alphabet-soup {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_soup {
    width: 900px;
    height: auto;
    height: auto;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 3rem;
}

.matrix {
    display: grid;
    grid-template-columns: repeat(15, 30px);
    /* gap: 2px; */
    margin: 0 auto;
    user-select: none;
    /* overflow: hidden; */
    touch-action: none;
}

.row {
    display: contents;
}

.cell {
    width: 30px !important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
}

.cell.selected {
    background-color: #d0e0f0;
}

.words-to-find ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: 5px;
}

.words-to-find ul.five-elements {
    grid-template-columns: repeat(1, 1fr);
}

.words-to-find ul.ten-elements {
    grid-template-columns: repeat(2, 1fr);
}

.words-to-find ul.fifteen-elements {
    grid-template-columns: repeat(2, 1fr);
}

.words-to-find ul.fifteen-elements li:nth-child(8) {
    grid-column: 1 / -1;
}

.words-to-find li {
    font-size: 14px;
    margin: 3px 0;
}

.words-to-find li.found {
    text-decoration: line-through;
}


@media screen and (max-width: 700px) {
    .container_soup {
        width: 90%;
        height: auto;
        display: block;
    }
}


@media screen and (max-width: 480px) {
    .container_soup {
        width: 100%;
        height: auto;
        display: block;
        padding: 1rem;
        margin-bottom: 2rem;
    }



    .matrix {
        grid-template-columns: repeat(15, 27px);
    }


    .cell {
        width: 27px !important;
        height: 27px !important;
        font-size: 12px;
    }
}

@media screen and (max-width: 431px) {
    .container_soup {
        width: 100%;
        height: auto;
        display: block;
        padding: 1rem;
        margin-bottom: 2rem;
    }



    .matrix {
        grid-template-columns: repeat(15, 23px);
    }


    .cell {
        width: 23px !important;
        height: 23px !important;
        font-size: 12px;
    }
}

@media screen and (max-width: 360px) {

    .matrix {
        grid-template-columns: repeat(15, 18px);
    }

    .cell {
        padding: 10px !important;
        width: 18px !important;
        height: 18px !important;
        font-size: 10px;
    }
}