@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --color-primary: #925FE2;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-black-24: #00000024;
    --color-text-secondary: #00000050;
    --color-text-tree: #00000080;
    --color-white-text-secondary: #ffffff75;
    --color-white-text-tercer: #ffffff50;
    --color-gradient-1: linear-gradient(159deg, #925FE2 0%, #925FE2 50%, #925FE280 100%, #DFCFF7 100%);
    --color-gradient-2: linear-gradient(to bottom, #925FE2, #7042C0);
    /* --color-gradient-3: linear-gradient(to top, #925FE2 0%, #925FE2 100%, #E2D4F7 100%); */
    --color-gradient-3: linear-gradient(to top,#925FE2 0%, #925FE2 80%, #925FE280 100%, #E2D4F7 100%);

    --color-gradient-4: #9C6FE430;

    --font-family: 'Poppins';
    --border-radius: 24px;
}

*{
    box-sizing: border-box;
    font-family: var(--font-family);
}

.container__{
    width: 100%;
    height: 100vh;
    padding: 32px;
    display: flex;
    gap: 48px;
}

.sidebar__{
    width: 233px;
    height: 100%;
    background: var(--color-gradient-3);
    border-radius: var(--border-radius);
    padding: 48px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container__logo__{
    display: flex;
   justify-content: center;
}

.logo__{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 128px;
    background: var(--color-gradient-2);
    border-radius: var(--border-radius);
    padding: 22px;
}

.logo__ img{
    width: 100px;
    height: auto;
}

.menu__{
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin-top: 48px;
}

.menu__ li {
    list-style: none;
    display: flex;
    gap: 12px;    
    padding: 10px 25px 10px 10px;
    border-radius: var(--border-radius);
}

.menu__ li img{
    opacity: .75;
}


.menu__ li a{
    text-decoration: none;
    color: var(--color-white-text-tercer);
    font-weight: 600;
}

img.active__{
    opacity: 1 !important;
}

.active__{
    color: var(--color-white) !important;
}

/* MAIN */
.main__{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
}

/* HEADER */

.header__{
    width: 100%;
    display: flex;
    justify-content: end;
}

.header__ .profile__{
    display: flex;
    gap: 12px;
}

.header__ img{
    width: 48px;
    height: 48px;
    border: 4px solid var(--color-primary);
    border-radius: 50%;
    box-shadow: 8px 8px 48px 8px var(--color-black-24);
}

.header__ .names__{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: var(--color-text-secondary);
    font-weight: 500;
}

.header__ .names__ .bold__{
    color: var(--color-black);
    font-weight: 600;
}

/* BANNER */

.banner__{
    position: relative;
    width: 100%;
    height: auto;
    background: var(--color-gradient-1);
    border-radius: var(--border-radius);
    padding: 48px 59px;
}

.banner__ .texto__{
    display: flex;
    flex-direction: column;
    gap: 63px;
    color: var(--color-white-text-secondary);
    font-size: 16px;
    font-weight: 400;
}

.banner__ .info__{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.banner__ .info__ .titulo__{
    font-size: 32px;
    font-weight: 600;
    color: var(--color-white);
}


.banner__ .student__img__{
    position: absolute;
    bottom: 0;
    right: 59px;
    z-index: 10;
}

.banner__ .scholarcap__img__{
    position: absolute;
    bottom: 0;
    right: 162px;
}

.banner__ .backpack__img__{
    position: absolute;
    bottom: 0;
    right: 0;
}

/* DASHBOARD */

.main__titulo__{
    font-size: 20px;
    font-weight: 600;
}

.main__container__datos{
    display: flex;
    gap: 25px;
    margin-bottom: -5px;
}

.main__subtitulo__{
    font-size: 20px;
    font-weight: bold;
    color: var(--color-primary);
}

.main__datos__{
    font-size: 20px;
    font-weight: 600;
    color: var(--color-text-tree);
}

.container__perfil{
    display: flex;
    gap: 60px;
}

.divisor__perfil{
    width: 2px;
    height: auto;
    background: var(--color-gradient-4);
    border-radius: 3px;
}

.container__alumno{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.main__container__{
    height: 100vh;
    /* display: grid;
    grid-template-columns: 3fr 1fr; */
    display: flex;
    justify-content: space-between;
    gap: 48px;
    flex: 1 1 auto;
    overflow-y: hidden;
    padding-right: 32px;
}

.main__container__left{
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 1 auto;
    overflow-y: auto;
}

.main__container__left::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.main__container__cards{
    width: 100%;
    height: 100%;
    
}

.main__container__cards .main__row{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}

.main__container__cards .main__row .main__col{
    width: 100%;
    height: auto;
}

.main__container__cards .main__item__card{
    width: 100%;
    height: auto;
    border: 4px solid var(--color-primary);
    border-radius: var(--border-radius);
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
}

.main__item__card:hover{
    background: var(--color-gradient-4);
}

.main__container__cards .main__item__card .main__item__Card__info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main__item__card__container__info{
    display: flex;
    flex-direction: column;
}

.main__item__card__titulo__{
    color: var(--color-primary);
    font-weight: 600;
    font-size: 18px;
}

.main__item__card__subtitulo__{
    color: var(--color-text-secondary);
    font-weight: 600;
    font-size: 16px;
}

.main__btn{
    border: none;
    padding: 6px 48px;
    border-radius: var(--border-radius);
    font-size: 18px;
    font-weight: 600;
}

.btn__regresar{
    display: flex;
    justify-content: end;
    margin-top: 20px;
}

.main__btn__primary{
    background-color: var(--color-primary);
    color: var(--color-white);
}

.main__btn__outline__primary{
    background-color: transparent;
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
}


.main__container__right{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 32px;
}

.main__container__right .main__container__profiles{
    display: flex;
    gap: 12px;
}

.main__container__right .main__container__profiles img{
    width: 89px;
    height: auto;
    border: 4px solid var(--color-primary);
    border-radius: 50%;
}

.main__container__right .main__container__mensajes{
    width: 100%;
    height: 100%;
    box-shadow: 8px 8px 48px 8px var(--color-black-24);
    border-radius: var(--border-radius);
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 1 auto;
    overflow-y: auto;
}

.main__item__mensaje{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.main__titulo__mensajes{
    font-size: 16px;
    font-weight: 600;
}

.main__subtitulo__mensajes{
    color: var(--color-text-secondary);
    font-size: 16px;
    font-weight: 600;
}

.main__date__mensajes{
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 600;
}

.main__container__mensajes::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Optional: just to make scrollbar invisible */
}

.btn_segundo{
    display: none;
}

.btn_primero{
    display: flex;
}

@media only screen and (min-width: 100px) {

    .container__{
        padding: 10px;
        gap: 10px;
        display: block;
    }

    .sidebar__{
        width: 100%;
        height: auto;
        border-radius: var(--border-radius);
        padding: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }

    .logo__{
        width: 40px;
        height: 40px;
        background: var(--color-gradient-2);
        border-radius: var(--border-radius);
        padding: 40px;
    }

    .menu__{
        gap: 15px;
        margin-top: 15px;
    }

    .menu__ li{
        font-size: 12px;
    }

    .menu__ li img{
        width: 16px;
    }

    .main__{
        gap: 17px;
    }

    .header__ img{
        width: 30px;
        height: 30px;
        border: 2px solid var(--color-primary);
        border-radius: 50%;
        box-shadow: 8px 8px 48px 8px var(--color-black-24);
    }
    
    .header__ .names__{
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: var(--color-text-secondary);
        font-weight: 500;
    }
    
    .header__ .names__ .bold__{
        color: var(--color-black);
        font-weight: 600;
    }

    .banner__{
        padding: 18px;
    }
    
    .banner__ .texto__{
        gap: 28px;
        font-size: 10px;    
    }

    .banner__ .info__{
        gap: 6px;
    }
    
    .banner__ .info__ .titulo__{
        font-size: 18px;
    }
    
    
    .banner__ .student__img__{
        display: none;
    }
    
    .banner__ .scholarcap__img__{
        display: none;
    }
    
    .banner__ .backpack__img__{
        display: none;
    }

    .main__container__{
        gap: 30px;
        padding-right: 10px;
        display: block;
    }

    .main__titulo__{
        font-size: 10px;
    }

    .main__subtitulo__{
        font-size: 12px;
    }

    .main__datos__{
        font-size: 12px;
    }

    .main__container__left{
        margin-bottom: 10px;
    }

    .container__alumno{
        margin-bottom: 30px;
    }

    .container__perfil{
        display: block;
    }

    .btn_segundo{
        display: flex;
    }

    .btn_primero{
        display: none;
    }

    .main__container__cards .main__row{
        gap: 10px;
    }

    .main__container__cards .main__row{
        grid-template-columns: 1fr ;
    }

    .main__container__cards .main__item__card{
        border: 2px solid var(--color-primary);
        padding: 10px 18px;
    }

    .main__container__cards .main__item__card img{
        width: 80px;
    }

    .main__item__card__titulo__{
        font-size: 12px;
    }
    
    .main__item__card__subtitulo__{
        font-size: 10px;
    }
    
    .main__btn{
        padding: 3px 28px;
        font-size: 12px;
    }


    .main__container__right{
        gap: 10px;
        padding-bottom: 5px;
    }
    
    .main__container__right .main__container__profiles img{
        width: 49px;
        border: 2px solid var(--color-primary);
    }
    
    .main__container__right .main__container__mensajes{
        max-height: 200px;
        padding: 17px;
        gap: 14px;
        margin-bottom: 10px;
    }
    
    .main__titulo__mensajes{
        font-size: 12px;
        font-weight: 600;
    }
    
    .main__subtitulo__mensajes{
        font-size: 10px;
    }
    
    .main__date__mensajes{
        font-size: 10px;
    }
}

@media only screen and (min-width: 375px) {

    .container__{
        padding: 10px;
        gap: 10px;
        display: block;
    }

    .sidebar__{
        width: 100%;
        height: auto;
        border-radius: var(--border-radius);
        padding: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }

    .logo__{
        width: 40px;
        height: 40px;
        background: var(--color-gradient-2);
        border-radius: var(--border-radius);
        padding: 40px;
    }

    .menu__{
        gap: 15px;
        margin-top: 15px;
    }

    .menu__ li{
        font-size: 12px;
    }

    .menu__ li img{
        width: 16px;
    }

    .main__{
        gap: 17px;
    }

    .header__ img{
        width: 30px;
        height: 30px;
        border: 2px solid var(--color-primary);
        border-radius: 50%;
        box-shadow: 8px 8px 48px 8px var(--color-black-24);
    }
    
    .header__ .names__{
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: var(--color-text-secondary);
        font-weight: 500;
    }
    
    .header__ .names__ .bold__{
        color: var(--color-black);
        font-weight: 600;
    }

    .banner__{
        padding: 18px;
    }
    
    .banner__ .texto__{
        gap: 28px;
        font-size: 10px;    
    }

    .banner__ .info__{
        gap: 6px;
    }
    
    .banner__ .info__ .titulo__{
        font-size: 18px;
    }
    
    
    .banner__ .student__img__{
        display: none;
    }
    
    .banner__ .scholarcap__img__{
        display: none;
    }
    
    .banner__ .backpack__img__{
        display: none;
    }

    .main__container__{
        gap: 30px;
        padding-right: 10px;
        display: block;
    }

    .main__titulo__{
        font-size: 10px;
    }

    .main__subtitulo__{
        font-size: 12px;
    }

    .main__datos__{
        font-size: 12px;
    }

    .main__container__left{
        margin-bottom: 10px;
    }

    .container__alumno{
        margin-bottom: 30px;
    }

    .container__perfil{
        display: block;
    }

    .btn_segundo{
        display: flex;
    }

    .btn_primero{
        display: none;
    }

    .main__container__cards .main__row{
        gap: 10px;
    }

    .main__container__cards .main__row{
        grid-template-columns: 1fr ;
    }

    .main__container__cards .main__item__card{
        border: 2px solid var(--color-primary);
        padding: 10px 18px;
    }

    .main__container__cards .main__item__card img{
        width: 80px;
    }

    .main__item__card__titulo__{
        font-size: 12px;
    }
    
    .main__item__card__subtitulo__{
        font-size: 10px;
    }
    
    .main__btn{
        padding: 3px 28px;
        font-size: 12px;
    }


    .main__container__right{
        gap: 10px;
        padding-bottom: 5px;
    }
    
    .main__container__right .main__container__profiles img{
        width: 49px;
        border: 2px solid var(--color-primary);
    }
    
    .main__container__right .main__container__mensajes{
        max-height: 200px;
        padding: 17px;
        gap: 14px;
        margin-bottom: 10px;
    }
    
    .main__titulo__mensajes{
        font-size: 12px;
        font-weight: 600;
    }
    
    .main__subtitulo__mensajes{
        font-size: 10px;
    }
    
    .main__date__mensajes{
        font-size: 10px;
    }
}

@media only screen and (min-width: 650px) {

    .container__perfil{
        width: 100%;
        display: block;
    }

    .btn_segundo{
        display: flex;
    }
    
    .btn_primero{
        display: none;
    }

    .container__{
        padding: 10px;
        gap: 10px;
        display: block;
    }

    .sidebar__{
        width: 100%;
        height: auto;
        border-radius: var(--border-radius);
        padding: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }

    .logo__{
        width: 40px;
        height: 40px;
        background: var(--color-gradient-2);
        border-radius: var(--border-radius);
        padding: 40px;
    }

    .menu__{
        gap: 15px;
        margin-top: 15px;
    }

    .menu__ li{
        font-size: 12px;
    }

    .menu__ li img{
        width: 16px;
    }

    .main__{
        gap: 17px;
    }

    .header__ img{
        width: 30px;
        height: 30px;
        border: 2px solid var(--color-primary);
        border-radius: 50%;
        box-shadow: 8px 8px 48px 8px var(--color-black-24);
    }
    
    .header__ .names__{
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: var(--color-text-secondary);
        font-weight: 500;
    }
    
    .header__ .names__ .bold__{
        color: var(--color-black);
        font-weight: 600;
    }

    .banner__{
        padding: 18px;
    }
    
    .banner__ .texto__{
        gap: 28px;
        font-size: 10px;    
    }

    .banner__ .info__{
        gap: 6px;
    }
    
    .banner__ .info__ .titulo__{
        font-size: 18px;
    }
    
    
    .banner__ .student__img__{
        width: 120px;
        right: 50px;
        display: block;
    }
    
    .banner__ .scholarcap__img__{
        width: 200px;
        right: 100px;
        display: block;

    }
    
    .banner__ .backpack__img__{
        width: 90px;
        right: 0;
        display: block;
    }

    .main__container__{
        gap: 30px;
        padding-right: 10px;
        display: flex;
    }

    .main__titulo__{
        font-size: 10px;
    }

    .main__container__left{
        gap: 10px;
    }

    .main__container__cards .main__row{
        gap: 10px;
    }

    .main__container__cards .main__row{
        grid-template-columns: 1fr ;
    }

    .main__container__cards .main__item__card{
        border: 2px solid var(--color-primary);
        padding: 10px 18px;
    }

    .main__container__cards .main__item__card img{
        width: 80px;
    }

    .main__item__card__titulo__{
        font-size: 12px;
    }
    
    .main__item__card__subtitulo__{
        font-size: 10px;
    }
    
    .main__btn{
        padding: 3px 28px;
        font-size: 12px;
    }


    .main__container__right{
        gap: 10px;
        padding-bottom: 5px;
    }
    
    .main__container__right .main__container__profiles img{
        width: 49px;
        border: 2px solid var(--color-primary);
    }
    
    .main__container__right .main__container__mensajes{
        max-height: 200px;
        padding: 17px;
        gap: 14px;
    }
    
    .main__titulo__mensajes{
        font-size: 12px;
        font-weight: 600;
    }
    
    .main__subtitulo__mensajes{
        font-size: 10px;
    }
    
    .main__date__mensajes{
        font-size: 10px;
    }
}

@media only screen and (min-width: 950px) {

    .container__perfil{
        width: 100%;
        display: flex;
    }

    .btn_segundo{
        display: none;
    }
    
    .btn_primero{
        display: flex;
    }

    .container__{
        display: flex;
        padding: 17px;
        gap: 17px;
    }

    .sidebar__{
        width: 130px;
        height: 100%;
        border-radius: var(--border-radius);
        padding: 21px 80px;
    }

    .logo__{
        width: 80px;
        height: 80px;
        background: var(--color-gradient-2);
        border-radius: var(--border-radius);
        padding: 10px;
    }

    .menu__{
        gap: 15px;
        margin-top: 15px;
    }

    .menu__ li{
        font-size: 12px;
    }

    .menu__ li img{
        width: 16px;
        height: 16px;
    }

    .main__{
        gap: 17px;
    }

    .header__ img{
        width: 30px;
        height: 30px;
        border: 2px solid var(--color-primary);
        border-radius: 50%;
        box-shadow: 8px 8px 48px 8px var(--color-black-24);
    }
    
    .header__ .names__{
        display: flex;
        flex-direction: column;
        font-size: 12px;
        color: var(--color-text-secondary);
        font-weight: 500;
    }
    
    .header__ .names__ .bold__{
        color: var(--color-black);
        font-weight: 600;
    }

    .banner__{
        padding: 18px 29px;
    }
    
    .banner__ .texto__{
        gap: 28px;
        font-size: 12px;    
    }

    .banner__ .info__{
        gap: 6px;
    }
    
    .banner__ .info__ .titulo__{
        font-size: 22px;
    }
    
    
    .banner__ .student__img__{
        width: 140px;
        right: 55px;
    }
    
    .banner__ .scholarcap__img__{
        width: 260px;
        right: 120px;
    }
    
    .banner__ .backpack__img__{
        width: 120px;
        right: 0;
    }

    .main__container__{
        gap: 32px;
        padding-right: 17px;
    }

    .main__titulo__{
        font-size: 12px;
    }

    .main__container__left{
        gap: 12px;
    }

    .main__container__cards .main__row{
        gap: 12px;
    }

    .main__container__cards .main__row{
        grid-template-columns: 1fr 1fr;
    }

    .main__container__cards .main__item__card{
        border: 2px solid var(--color-primary);
        padding: 12px 20px;
    }

    .main__container__cards .main__item__card img{
        width: 90px;
    }

    .main__item__card__titulo__{
        font-size: 12px;
    }
    
    .main__item__card__subtitulo__{
        font-size: 10px;
    }
    
    .main__btn{
        padding: 3px 28px;
        font-size: 12px;
    }


    .main__container__right{
        gap: 10px;
        padding-bottom: 5px;
    }
    
    .main__container__right .main__container__profiles img{
        width: 49px;
        border: 2px solid var(--color-primary);
    }
    
    .main__container__right .main__container__mensajes{
        padding: 17px;
        gap: 14px;
    }
    
    .main__titulo__mensajes{
        font-size: 12px;
        font-weight: 600;
    }
    
    .main__subtitulo__mensajes{
        font-size: 10px;
    }
    
    .main__date__mensajes{
        font-size: 10px;
    }
}

@media only screen and (min-width: 1466px) {

    .container__{
        padding: 27px;
        gap: 27px;
    }

    .sidebar__{
        width: 200px;
        border-radius: var(--border-radius);
        padding: 41px 34px;
    }

    .logo__{
        width: 109px;
        height: 109px;
        background: var(--color-gradient-2);
        border-radius: var(--border-radius);
        padding: 22px;
    }

    .menu__{
        gap: 21px;
        margin-top: 21px;
    }

    .menu__ li{
        font-size: 14px;
    }

    .menu__ li img{
        width: 20px;
    }

    .main__{
        gap: 27px;
    }

    .header__ img{
        width: 38px;
        height: 38px;
        border: 3px solid var(--color-primary);
        border-radius: 50%;
        box-shadow: 8px 8px 48px 8px var(--color-black-24);
    }
    
    .header__ .names__{
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: var(--color-text-secondary);
        font-weight: 500;
    }
    
    .header__ .names__ .bold__{
        color: var(--color-black);
        font-weight: 600;
    }

    .banner__{
        padding: 38px 49px;
    }
    
    .banner__ .texto__{
        gap: 33px;
        font-size: 14px;
    }

    .banner__ .info__{
        gap: 10px;
    }
    
    .banner__ .info__ .titulo__{
        font-size: 28px;
    }
    
    
    .banner__ .student__img__{
        width: 200px;
        right: 55px;
    }
    
    .banner__ .scholarcap__img__{
        width: 350px;
        right: 122px;
    }
    
    .banner__ .backpack__img__{
        width: 140px;
        right: 0;
    }

    .main__container__{
        gap: 38px;
        padding-right: 27px;
    }

    .main__titulo__{
        font-size: 16px;
    }

    .main__container__left{
        gap: 14px;
    }

    .main__container__cards .main__row{
        gap: 14px;
    }

    .main__container__cards .main__item__card{
        border: 3px solid var(--color-primary);
        padding: 14px 22px;
    }

    .main__container__cards .main__item__card img{
        width: 110px;
    }

    .main__item__card__titulo__{
        font-size: 14px;
    }
    
    .main__item__card__subtitulo__{
        font-size: 12px;
    }
    
    .main__btn{
        padding: 6px 38px;
        font-size: 14px;
    }


    .main__container__right{
        gap: 14px;
        padding-bottom: 5px;
    }
    
    .main__container__right .main__container__profiles img{
        width: 59px;
        border: 3px solid var(--color-primary);
    }
    
    .main__container__right .main__container__mensajes{
        max-height: auto;
        padding: 17px;
        gap: 14px;
    }
    
    .main__titulo__mensajes{
        font-size: 14px;
        font-weight: 600;
    }
    
    .main__subtitulo__mensajes{
        font-size: 12px;
    }
    
    .main__date__mensajes{
        font-size: 12px;
    }
}
