:root{
    --bg-primary: #313A46;
    --color-text: #7E8C9D;
    --color-hover: #AEBFD4;
}

.principal {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
}
.sidebar {
    width: 290px;
    background-color: var(--bg-primary);
    color: var(--color-text);
    padding: 20px;
    height: 100vh;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar .salir ul {
    list-style-type: none;
    padding: 0;
}
.sidebar .salir ul li {
    margin: 10px 0;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    gap: 30px;
}

.sidebar .salir ul li {
    color: var(--color-text);
    padding: 10px;
    border-radius: 4px;
    transition: background 0.3s;
}

.sidebar .salir ul li i {
    width: 10px;
}

.sidebar .salir ul li a {
    color: var(--color-text);
    text-decoration: none;
    display: block;
}
.sidebar .salir ul li:hover { 
    color: var(--color-hover);
    background-color: #34495e;
}

.sidebar .salir ul li:hover a { 
    color: var(--color-hover);
}

.sidebar h2 {
    text-align: center;
}
.sidebar .profile {
    text-align: center;
    margin-bottom: 20px;
}
.sidebar .profile img {
    width: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}
.sidebar .profile h3 {
    margin: 0;
}
.sidebar nav ul {
    list-style-type: none;
    padding: 0;
}
.sidebar nav ul li {
    margin: 10px 0;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    gap: 30px;
}

.sidebar nav ul li {
    color: var(--color-text);
    padding: 10px;
    border-radius: 4px;
    transition: background 0.3s;
}

.sidebar nav ul li i {
    width: 10px;
}

.sidebar nav ul a {
    color: var(--color-text);
    text-decoration: none;
    display: block;
}
.sidebar nav ul li:hover { 
    color: var(--color-hover);
    background-color: #34495e;
}
.sidebar nav ul li:hover a { 
    color: var(--color-hover);
}

.sidebar nav ul li.active { 
    color: var(--color-hover);
    background-color: #34495e;
}
.sidebar nav ul li.active a { 
    color: var(--color-hover);
}

.content {
    flex: 1;
    max-height: 100VH;
    padding: 20px;
    overflow: auto; /* Permite que el contenido tenga scroll si es más largo que la ventana */
    box-sizing: border-box;
}

/* .content {
    flex-grow: 1;
    padding: 20px;
} */

.logo{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto;
    background-color: white;
}