.menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 8rem 0;
    background-color: #2c3e50;
  }
  
  .menu-title {
    font-size: 3rem;
    color: #ecf0f1;
    margin: 0 2rem;
    margin-bottom: 2rem;
    font-family: 'Arial', sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .menu-button {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    color: #ecf0f1;
    background-color: #e74c3c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .menu-button:hover {
    background-color: #c0392b;
    transform: scale(1.05);
  }
  
  .menu-button:active {
    transform: scale(1);
    background-color: #e74c3c;
  }
  

  .fullscreen-toggle {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white; /* Cambia este color según tu diseño */
    padding: 10px;
}

.fullscreen-toggle:hover {
    color: white; /* Cambia este color según tu diseño */
}