*{
    box-sizing: border-box;
}

.pizarra{
    width: 650px;
    height: 500px;
    background-image: url("../img/pizarra.png");
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pizarra h1{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    pointer-events: none;
    margin: 20px 0;
}

.container_button{
    display: flex;
    gap: 10px;
}

.pizarra button{
    border: none;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 600;
    background-color: #00000076;
    color: white;
}

.pizarra button:hover{
    font-size: 22px;
    background-color: #000000b3;
}

.estrellas{
    display: flex;
    gap: 5px;
}

.respuesta{
    margin-top: 20px;
    color: white;
    font-weight: bold;
}

.titulo{
    margin-top: 10px;
    font-size: 24px;
    color: white;
}