* {
    box-sizing: border-box;
}

.puzzle-wrapper {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-preview {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    border: 2px solid #000;
    padding: 5px;
    background-color: #fff;
}

.image-preview img {
    width: 100%; /* Tamaño de la imagen en vista previa */
    height: auto;
}

.puzzle-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(var(--grid-size), 1fr);
    grid-template-rows: repeat(var(--grid-size), 1fr);
    width: 500px;
    height: 500px;
    gap: 2px;
    margin-top: 1rem;
    touch-action: none;
}

.puzzle-piece {
    background-size: 500px 500px;
    cursor: pointer;
}

@media screen and (max-width: 580px) {


    .puzzle-container {
        display: grid;
        grid-template-columns: repeat(var(--grid-size), 1fr);
        grid-template-rows: repeat(var(--grid-size), 1fr);
        width: 400px;
        height: 400px;
        gap: 2px;
        margin: 0 auto;
        margin-top: 1rem;

    }
    
    .puzzle-piece {
        background-size: 400px 400px;
        cursor: pointer;
    }
    

}


@media screen and (max-width: 470px) {
    .puzzle-container {
        width: 300px;
        height: 300px;
    }
    
    .puzzle-piece {
        background-size: 300px 300px;
    }
    
}


@media screen and (max-width: 350px) {
    .puzzle-container {
        width: 250px;
        height: 250px;
    }
    
    .puzzle-piece {
        background-size: 250px 250px;
    }
    
}